"use strict";

// noinspection JSUnusedGlobalSymbols
function enableMobileClick(debug = false) {
	// noinspection JSUnresolvedVariable,ES6ConvertVarToLetConst
	const SwisscomMessage = 'SwisscomMessage';
	const SwisscomBlueMessage = 'SwisscomTeleclubMessage';
	const message = debug
		? {
			postMessage: (value) => {
				console.warn("postMessage, would send:", value);
			},
		}
		: !!window.webkit.messageHandlers[SwisscomMessage]
			? window.webkit.messageHandlers[SwisscomMessage]
			: window.webkit.messageHandlers[SwisscomBlueMessage];


	document.body.addEventListener('click', function (event) {
		const elements = ['rnb-clip-card', 'rnb-series-card', 'rnb-video-card', 'rnb-tv-card', 'rnb-participant-card', 'rnb-recording-card', 'rnb-sport-card', 'rnb-channel-card', 'a.link'];
		let clickedElement = getClosestParentElement(event.target, elements[0]); // check series card

		// check other type of elements
		if (!clickedElement) {
			// noinspection ES6ConvertVarToLetConst
			for (var i = 1; i < elements.length; i++) {
				clickedElement = getClosestParentElement(event.target, elements[i]);
				if (clickedElement) {
					break;
				}
			}
		}

		if (clickedElement) {
			message.postMessage(JSON.stringify({
				assetType: clickedElement.getAttribute('data-type'),
				identifier: clickedElement.getAttribute('data-identifier'),
				sectionType: clickedElement.getAttribute('data-store'),
				title: clickedElement.getAttribute('data-title') || '',
			}));

			event.stopPropagation();
		}
	}, true);
}

// NOTE: the timestamp needs to be a unix timestamp in milliseconds (!), the lifetime a seconds value (e.g. 172800, not '2:00:00')
// noinspection JSUnusedGlobalSymbols
function setCookies(token, timestamp, lifetime, signature, user, language) {
	document.cookie = 'sessionTV=session=' + token + '#timestamp=' + timestamp + '#lifetime=' + lifetime + ';Secure';
	document.cookie = 'signatureTV=' + signature + ';Secure';
	document.cookie = 'userTV=' + user + ';Secure';
	document.cookie = 'languageTV=' + language + ';Secure';
}

function getClosestParentElement(elem, selector) {
	// Get closest match
	for (; elem && elem !== document; elem = elem.parentNode) {
		if (elem.matches(selector)) {
			return elem;
		}
	}
	return null;
}
